import baseUrl from '../globals';

const handleSubmit = (
  e,
  buttonEnabled,
  setButtonEnabled,
  username,
  password,
  email,
  setErrorCount,
  errorCount,
  setErrorMessage,
) => {
  e.preventDefault();
  if (buttonEnabled === false) {
    return;
  }
  setButtonEnabled(false);

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password, email }),
    credentials: 'include',
  };

  const failed = (message) => {
    setErrorCount(errorCount + 1);
    setErrorMessage(message);
    setButtonEnabled(true);
  };

  fetch(`${baseUrl}/register`, requestOptions)
    .then((res) => {
      if (res.status === 200) {
        window.location.href = '/';
      }
      return res;
    })
    .then((res) => res.json())
    .then((data) => {
      failed(data.message);
    })
    .catch(() => failed('Sorry the server cannot be reached right now'));
};

export default handleSubmit;
