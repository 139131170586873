import React from 'react';
import './PageSelector.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserUsername } from './User/userSlice';

const PageSelector = () => {
  const username = useSelector(selectUserUsername);

  return (
    <div className="m-0 row selector">
      <div className="col-lg-3 left-col">
        <div className="left-col-container">
          <h1 className="left-col-text">{`Hi ${username}`}</h1>
        </div>
      </div>
      <div className="col-lg-9" style={{ display: 'flex', minHeight: '100vh' }}>
        <div
          className="container selector-col"
          style={{ height: '100%', margin: 'auto' }}
        >
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-2 selector-image-container">
              <span className="option-circle" />
              <Link to="/predictions" className="option-icon">
                <img
                  alt="question mark icon"
                  className="option-icon"
                  height={100}
                  src="/icons/question-mark.png"
                />
              </Link>
            </div>
            <div className="col-md-6">
              <div className="selector-option-container">
                <h4 className="option-title text-md-left text-center">
                  <Link to="/predictions" className="option-title-link">
                    Predictions
                  </Link>
                </h4>
                <h4 className="option-description text-md-left text-center">
                  Predict Premier League results
                </h4>
              </div>
            </div>
            <div className="col-md-2 last-col" />
          </div>
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-2 selector-image-container">
              <span className="option-circle" />
              <Link to="/minileagues" className="option-icon">
                <img
                  alt="trophy icon"
                  className="option-icon"
                  height={100}
                  src="/icons/trophy.png"
                />
              </Link>
            </div>
            <div className="col-md-6">
              <div className="selector-option-container">
                <h4 className="option-title text-md-left text-center">
                  <Link to="/minileagues" className="option-title-link">
                    Mini-leagues
                  </Link>
                </h4>
                <h4 className="option-description text-md-left text-center">
                  Compare predictions
                </h4>
              </div>
            </div>
            <div className="col-md-2 last-col" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSelector;
