import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import HomepageButton from '../HomepageButton';
import GameweekPointsGraph from './GameweekPointsGraph';
import DropdownSelector from '../DropdownSelector';

const Stats = () => {
  const PREQUERY = gql`
    query {
      findGameweek {
        number
      }
    }
  `;

  const [gameweek, setGameweek] = useState(5);

  useQuery(PREQUERY, {
    onCompleted: (data) => {
      setGameweek(data.findGameweek.number);
    },
    returnPartialData: true,
  });

  let weeklyPointsFields = '';
  for (let i = 1; i <= 38; i += 1) {
    weeklyPointsFields += `weeklyPoints${i}: weeklyPoints(gameweek: ${i})\n`;
  }

  const QUERY = gql`
  query {
    minileagueMany {
      members {
        _id
        username
        totalPoints
        ${weeklyPointsFields}
      }
    }
  }`;

  const { data: pointsData, loading: pointsDataLoading } = useQuery(QUERY, {
    returnPartialData: false,
  });

  return (
    <div>
      <HomepageButton />
      <h1>Stats</h1>
      {!pointsDataLoading && (
        <div>
          <div>
            <div style={{ display: 'inline-block', marginLeft: 10 }}>
              <DropdownSelector
                length={38}
                onValueUpdate={(e) => setGameweek(parseInt(e.target.value, 10))}
                startingValue={gameweek}
              />
            </div>
          </div>
          <GameweekPointsGraph
            data={pointsData.minileagueMany[0].members.map((member) => ({
              label: member.username,
              value: member[`weeklyPoints${gameweek}`],
            }))}
          />
        </div>
      )}
    </div>
  );
};

export default Stats;
