import React, { Suspense, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import ReactLoading from 'react-loading';
import MiniLeagues from './MiniLeagues/MiniLeagues';
import Predictions from './Predictions/Predictions';
import Stats from './Stats/Stats';
import './MiniLeagues/MiniLeagues.css';
import './Predictions/Predictions.css';

import {
  getUserInfo,
  selectLoggedIn,
  selectSleekplanJWT,
  selectUserEmail,
  selectUserID,
  selectUserUsername,
} from './User/userSlice';

import Scoring from './Scoring/Scoring';
import FeedbackPopup from './Feedback/FeedbackPopup';
import FrontPage from './FrontPage';
import PageSelector from './PageSelector';
import ResetPassword from './ResetPassword';
import CreateNewPassword from './CreateNewPassword';

import BetaStamp from './BetaStamp';
import {
  selectAlertVariant,
  selectAlertMessage,
  clearAlert,
} from './Alerts/alertsSlice';

const App = () => {
  const dispatch = useDispatch();

  const alertVariant = useSelector(selectAlertVariant);
  const alertMessage = useSelector(selectAlertMessage);
  const apiCookie = useSelector(selectLoggedIn);
  const username = useSelector(selectUserUsername);
  const email = useSelector(selectUserEmail);
  const id = useSelector(selectUserID);
  const sleekplanJWT = useSelector(selectSleekplanJWT);

  if (!apiCookie) {
    dispatch(getUserInfo());
  }

  const QUERY = gql`
    query {
      findGameweek {
        number
      }
    }
  `;

  useQuery(QUERY, { skip: !apiCookie });

  useEffect(() => {
    if (apiCookie) {
      window.document.addEventListener(
        'sleek:init',
        () => {
          const user = {
            mail: email,
            id,
            name: username,
            token: sleekplanJWT,
          };
          window.$sleek.setUser(user);
        },
        false,
      );
    }
  }, [apiCookie]);

  return apiCookie !== null ? (
    <div>
      {alertMessage && (
        <div className="feedback-success-container">
          <Alert
            variant={alertVariant}
            className="feedback-success"
            onClose={() => dispatch(clearAlert())}
            dismissible
          >
            {alertMessage}
          </Alert>
        </div>
      )}
      <FeedbackPopup />
      <BetaStamp />
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {apiCookie && <Route path="/minileagues" component={MiniLeagues} />}
            {apiCookie && <Route path="/predictions" component={Predictions} />}
            {apiCookie && <Route path="/stats" component={Stats} />}
            {apiCookie && (
              <Route path="/scores">
                <Scoring />
              </Route>
            )}
            <Route path="/register">
              <FrontPage widget="Register" />
            </Route>
            <Route path="/resetpassword">
              <ResetPassword />
            </Route>
            <Route
              path="/createnewpassword"
              component={(routeProps) => (
                <CreateNewPassword location={routeProps.location} />
              )}
            />
            <Route path="/">
              {apiCookie ? <PageSelector /> : <FrontPage widget="Login" />}
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </div>
  ) : (
    <div className="loader-container">
      <ReactLoading type="spin" height="fit-content" className="loader-icon" />
    </div>
  );
};

export default Sentry.withProfiler(App);
