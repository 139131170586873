/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { gql, useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import DropdownSelector from '../DropdownSelector';
import HomepageButton from '../HomepageButton';
import handleSubmit from '../Logic/PredictionsLogic';
import { selectUserID } from '../User/userSlice';

const Predictions = () => {
  const userID = useSelector(selectUserID);
  const [gameweek, setGameweek] = useState();
  const inputRefs = useRef([]);

  const QUERY = gql`
    query {
      matchMany(filter: {gameweek: ${gameweek || 0}}) {
        _id
        home_team
        away_team
        live_home_score
        live_away_score
        kick_off_time
        gameweek
        locked
        predictions${userID ? `(users: ["${userID}"])` : ''} {
          _id
          home_pred
          away_pred
          banker
          insurance
          points
        }
      }
    }
  `;

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(QUERY);
  if (queryError) {
    throw new Error(queryError);
  }

  useEffect(() => {
    if (queryData?.matchMany[0].gameweek !== gameweek && queryData) {
      setGameweek(queryData.matchMany[0].gameweek);
    }
  }, [queryData, gameweek]);

  useEffect(() => {
    if (window.scrollY < 100 && window.screen.width < 992) {
      window.scrollTo(0, 100);
    }
  }, []);

  const BATCHED_MUTATION = gql`
    mutation {
      updatePrediction {
        home_pred
      }
    }
  `;

  const [
    updatePredictions,
    {
      error: mutationError,
      loading: mutationLoading,
      called: mutationCalled,
      data: mutationData,
    },
  ] = useMutation(BATCHED_MUTATION);
  if (mutationError) {
    throw new Error(mutationError);
  }

  const bankerUsed =
    mutationData && Object.values(mutationData)?.some((match) => match?.banker);
  const insuranceUsed =
    mutationData &&
    Object.values(mutationData)?.some((match) => match?.insurance);

  return (
    <div className="m-0 row">
      {!mutationLoading &&
        !mutationError &&
        mutationCalled &&
        (bankerUsed && insuranceUsed ? (
          <Alert
            variant="success"
            style={{
              textAlign: 'center',
              position: 'fixed',
              left: 0,
              right: 0,
              margin: 'auto',
              width: '80%',
              marginTop: 10,
              zIndex: 99999999,
            }}
          >
            Predictions successfully updated!
            <br />
            Please check your email for confirmation.
          </Alert>
        ) : (
          <Alert variant="warning" style={{ textAlign: 'center' }}>
            Predictions successfully updated!
            <br />
            You have not used your
            {!bankerUsed && ' banker'}
            {!bankerUsed && !insuranceUsed && ' or'}
            {!insuranceUsed && ' insurance'}
            .
            <br />
            Please check your email for confirmation.
          </Alert>
        ))}
      <div className="col-lg-3 left-col-prediction-outer-container">
        <HomepageButton />
        <div className="left-col-prediction-container">
          <h1 className="left-col-prediction-text">Predictions</h1>
          <DropdownSelector
            enabled={queryLoading}
            length={38}
            onValueUpdate={(e) => setGameweek(parseInt(e.target.value, 10))}
            startingValue={gameweek}
          />
          <input
            disabled={mutationLoading}
            className="predictions-form-submit-button"
            type="submit"
            value="Submit"
            form="predictions-form"
          />
          <div className="explanation-container">
            <div className="explanation-row-container">
              <span
                className="badge rounded-pill bg-success"
                style={{ '--bs-bg-opacity': '.7', fontSize: '1rem' }}
              >
                30
              </span>
              <span> points for correct score</span>
            </div>
            <div className="explanation-row-container">
              <span
                className="badge rounded-pill bg-success"
                style={{ '--bs-bg-opacity': '.7', fontSize: '1rem' }}
              >
                15
              </span>
              <span> points for correct result and goal difference</span>
            </div>
            <div className="explanation-row-container">
              <span
                className="badge rounded-pill bg-success"
                style={{ '--bs-bg-opacity': '.7', fontSize: '1rem' }}
              >
                10
              </span>
              <span> points for correct result</span>
            </div>
            <div className="explanation-row-container">
              <span
                className="badge rounded-pill bg-danger"
                style={{ '--bs-bg-opacity': '.7', fontSize: '1rem' }}
              >
                -10
              </span>
              <span> points for incorrect result</span>
            </div>
            <div className="explanation-row-container">
              <img
                className="chip-icon"
                alt="dollar icon"
                src="/icons/dollar.png"
                height={30}
                style={{ filter: 'none' }}
              />
              <span> banker - x3 points</span>
            </div>
            <div className="explanation-row-container">
              <img
                className="chip-icon"
                alt="padlock icon"
                src="/icons/padlock.png"
                height={30}
                style={{ filter: 'none' }}
              />
              <span> insurance - minimum 0 points</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-9 right-col">
        <form
          id="predictions-form"
          className="predictions-form"
          onSubmit={(e) => handleSubmit(e, updatePredictions)}
        >
          {queryData &&
            queryData.matchMany.map((match, idx) => {
              const kickOffTime = new Date(match.kick_off_time);
              return (
                <PredictionRow
                  // eslint-disable-next-line no-underscore-dangle
                  key={match._id}
                  kickOffTime={kickOffTime}
                  match={match}
                  inputRefs={inputRefs}
                  idx={idx}
                />
              );
            })}
          <input
            disabled={mutationLoading}
            className="predictions-form-submit-button predictions-form-submit-button-mobile"
            type="submit"
            value="Submit"
            form="predictions-form"
          />
        </form>
      </div>
    </div>
  );
};

const PredictionRow = ({ kickOffTime, match, inputRefs, idx }) => {
  const month = [];
  month[0] = 'January';
  month[1] = 'February';
  month[2] = 'March';
  month[3] = 'April';
  month[4] = 'May';
  month[5] = 'June';
  month[6] = 'July';
  month[7] = 'August';
  month[8] = 'September';
  month[9] = 'October';
  month[10] = 'November';
  month[11] = 'December';

  const [bankerEnabled, setBankerEnabled] = useState(
    !!match.predictions[0]?.banker,
  );
  const [insuranceEnabled, setInsuranceEnabled] = useState(
    !!match.predictions[0]?.insurance,
  );

  const handleInputChange = (e, currentIndex) => {
    if (e.target.value.length === 1 && inputRefs.current[currentIndex + 1]) {
      inputRefs.current[currentIndex + 1].focus();
    }
  };

  let backgroundColor;
  if (match.predictions[0]?.points > 0) {
    backgroundColor = 'bg-success';
  } else if (match.predictions[0]?.points < 0) {
    backgroundColor = 'bg-danger';
  } else {
    backgroundColor = 'bg-dark';
  }

  return (
    // eslint-disable-next-line no-underscore-dangle
    <div className="outer-container" key={match._id}>
      <div className="outer-pred-container" style={{ marginBottom: 20 }}>
        <div className="pred-container">
          <div className="home-team-container">
            <div className="team-container">
              <img
                alt="home club badge"
                className="club-badge"
                height={70}
                src={`/badges/${match.home_team}.png`}
              />
              <span className="club-name">{match.home_team}</span>
            </div>
          </div>
          <div>
            <div className="score-container">
              <div className="kick-off-time-container">
                {kickOffTime.getDate()} {month[kickOffTime.getMonth()]}{' '}
                {kickOffTime.getHours()}:
                {`0${kickOffTime.getMinutes()}`.slice(-2)}
              </div>
              {!match.locked ? (
                <>
                  <input
                    className="prediction-score-input"
                    disabled={!!match.locked}
                    // eslint-disable-next-line no-underscore-dangle
                    name={`${match._id}[home-pred]`}
                    type="number"
                    style={{
                      textAlign: 'center',
                      backgroundColor: match.locked ? '#c5ccd6' : '',
                    }}
                    defaultValue={match.predictions[0]?.home_pred}
                    // eslint-disable-next-line no-return-assign, no-param-reassign
                    ref={(el) => (inputRefs.current[idx * 2] = el)}
                    onChange={(e) => handleInputChange(e, idx * 2)}
                  />
                  <span>-</span>
                  <input
                    className="prediction-score-input"
                    disabled={!!match.locked}
                    // eslint-disable-next-line no-underscore-dangle
                    name={`${match._id}[away-pred]`}
                    type="number"
                    style={{
                      textAlign: 'center',
                      backgroundColor: match.locked ? '#c5ccd6' : '',
                    }}
                    defaultValue={match.predictions[0]?.away_pred}
                    // eslint-disable-next-line no-return-assign, no-param-reassign
                    ref={(el) => (inputRefs.current[idx * 2 + 1] = el)}
                    onChange={(e) => handleInputChange(e, idx * 2 + 1)}
                  />
                </>
              ) : match.predictions[0]?.home_pred ||
                match.predictions[0]?.home_pred === 0 ? (
                <div
                  style={{
                    fontSize: '2.0rem',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ flex: 1 }} />
                  <div>
                    <span>
                      <strong>{match.predictions[0]?.home_pred}</strong>
                    </span>
                    <span>-</span>
                    <span>
                      <strong>{match.predictions[0]?.away_pred}</strong>
                    </span>
                  </div>
                  {bankerEnabled ? (
                    <div style={{ flex: 1 }}>
                      <img
                        className="chip-icon"
                        alt="banker icon"
                        src="/icons/dollar.png"
                        height={30}
                      />
                    </div>
                  ) : (
                    <div style={{ flex: 1 }} />
                  )}
                </div>
              ) : (
                <div style={{ fontSize: '2.0rem' }}>
                  <span>No prediction</span>
                </div>
              )}
              <div className="chips-container">
                {!match.locked && (
                  <>
                    <button
                      disabled={!!match.locked}
                      type="button"
                      style={{ opacity: bankerEnabled ? 1 : 0.1 }}
                      className="chip-icon-button"
                      onClick={() => setBankerEnabled(!bankerEnabled)}
                    >
                      <img
                        className="chip-icon"
                        alt="dollar icon"
                        src="/icons/dollar.png"
                        height={30}
                      />
                    </button>
                    <input
                      disabled={!!match.locked}
                      type="hidden"
                      // eslint-disable-next-line no-underscore-dangle
                      name={`${match._id}[banker]`}
                      value={bankerEnabled}
                    />
                    <button
                      disabled={!!match.locked}
                      type="button"
                      style={{ opacity: insuranceEnabled ? 1 : 0.1 }}
                      className="chip-icon-button"
                      onClick={() => setInsuranceEnabled(!insuranceEnabled)}
                    >
                      <img
                        className="chip-icon"
                        alt="padlock icon"
                        src="/icons/padlock.png"
                        height={30}
                      />
                    </button>
                    <input
                      disabled={!!match.locked}
                      type="hidden"
                      // eslint-disable-next-line no-underscore-dangle
                      name={`${match._id}[insurance]`}
                      value={insuranceEnabled}
                    />
                  </>
                )}
                {match.locked && (
                  <div>
                    {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                    <span>
                      Actual score: {match.live_home_score} -{' '}
                      {match.live_away_score}
                    </span>
                    <span
                      className={`badge rounded-pill ${backgroundColor}`}
                      style={{
                        '--bs-bg-opacity': '.7',
                        fontSize: '1.2rem',
                        marginLeft: 5,
                      }}
                    >
                      {match.predictions[0]?.points || 0}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="away-team-container">
            <div className="team-container">
              <img
                alt="away club badge"
                className="club-badge"
                height={70}
                src={`/badges/${match.away_team}.png`}
              />
              <span className="club-name">{match.away_team}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PredictionRow.propTypes = {
  kickOffTime: PropTypes.instanceOf(Date).isRequired,
  match: PropTypes.shape({
    _id: PropTypes.string,
    home_team: PropTypes.string,
    away_team: PropTypes.string,
    live_home_score: PropTypes.number,
    live_away_score: PropTypes.number,
    locked: PropTypes.bool,
    predictions: PropTypes.arrayOf(
      PropTypes.shape({
        home_pred: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        away_pred: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        banker: PropTypes.bool,
        insurance: PropTypes.bool,
        points: PropTypes.number,
      }),
    ),
  }).isRequired,
  inputRefs: PropTypes.shape({
    current: PropTypes.arrayOf(
      PropTypes.shape({
        focus: PropTypes.func,
      }),
    ),
  }).isRequired,
  idx: PropTypes.number.isRequired,
};

export default Predictions;
