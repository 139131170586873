import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

const GameweekPointsGraph = ({ data }) => {
  const width = 800;
  const height = 400;
  const margin = { top: 50, right: 50, bottom: 50, left: 50 };

  const scaleX = d3
    .scaleBand()
    .domain(data.map(({ label }) => label))
    .range([0, width])
    .padding(0.5);
  const scaleY = d3
    .scaleLinear()
    .domain([0, Math.max(...data.map(({ value }) => value))])
    .range([height, 0]);

  const bottomRef = useRef(null);
  useEffect(() => {
    if (bottomRef.current) {
      d3.select(bottomRef.current)
        .call(d3.axisBottom(scaleX).tickSize(0))
        .style('font-size', '20px');
    }
  }, [scaleX]);

  const leftRef = useRef(null);
  useEffect(() => {
    if (leftRef.current) {
      d3.select(leftRef.current).call(d3.axisLeft(scaleY).tickSize(0));
    }
  }, [scaleY]);

  return (
    <svg
      width={width + margin.left + margin.right}
      height={height + margin.top + margin.bottom}
    >
      <g transform={`translate(${margin.left}, ${margin.top})`}>
        <g ref={bottomRef} transform={`translate(0, ${height})`} />
        <g ref={leftRef} />

        {data.map(({ label, value }) => (
          <rect
            key={`bar-${label}`}
            x={scaleX(label)}
            y={scaleY(value)}
            width={scaleX.bandwidth()}
            height={height - scaleY(value)}
            fill="teal"
            rx={20}
            ry={20}
          />
        ))}
      </g>
    </svg>
  );
};

GameweekPointsGraph.defaultProps = {
  data: [],
};

GameweekPointsGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number),
};

export default GameweekPointsGraph;
