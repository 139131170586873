/* eslint-disable no-nested-ternary */
import React from 'react';
import { Table } from 'react-bootstrap';
import './MiniLeagueTable.css';
import PropTypes from 'prop-types';
import DropdownSelector from '../DropdownSelector';

const month = [];
month[0] = 'January';
month[1] = 'February';
month[2] = 'March';
month[3] = 'April';
month[4] = 'May';
month[5] = 'June';
month[6] = 'July';
month[7] = 'August';
month[8] = 'September';
month[9] = 'October';
month[10] = 'November';
month[11] = 'December';

const MiniLeagueTable = ({
  table,
  setGameweek,
  gameweek,
  loaded,
  selectedMiniLeagueName,
}) => (
  <div className="minileague-prediction-container">
    {table.length > 1 ? (
      <>
        <DropdownSelector
          enabled={!loaded}
          onValueUpdate={(e) => setGameweek(parseInt(e.target.value, 10))}
          startingValue={gameweek}
          length={38}
        />
        <Table borderless className="minileague-prediction-table mini-table">
          <thead>
            <tr>
              <th>Home Team vs Away Team</th>
              <th>Predictions</th>
            </tr>
          </thead>
          <tbody>
            {table.map((match) => {
              const kickOffTime = new Date(match.kick_off_time);
              return (
                // eslint-disable-next-line no-underscore-dangle
                <React.Fragment key={match._id}>
                  <tr className="minileague-prediction-table-row-clickable minileague-prediction-table-row">
                    <td>
                      {/* eslint-disable-next-line no-underscore-dangle */}
                      <div className="outer-container" key={match._id}>
                        <div className="outer-pred-container">
                          <div className="pred-container">
                            <div className="home-team-container">
                              <div className="team-container">
                                <img
                                  alt="home club badge"
                                  className="club-badge"
                                  height={70}
                                  src={`/badges/${match.home_team}.png`}
                                />
                                <span className="club-name">
                                  {match.home_team}
                                </span>
                              </div>
                            </div>
                            <div>
                              <div className="kick-off-time-container">
                                {kickOffTime.getDate()}{' '}
                                {month[kickOffTime.getMonth()]}{' '}
                                {kickOffTime.getHours()}:
                                {`0${kickOffTime.getMinutes()}`.slice(-2)}
                              </div>
                              <div
                                className="score-container"
                                style={{ marginLeft: 10, marginRight: 10 }}
                              >
                                {!match.locked ? (
                                  <>
                                    <div>Not kicked off</div>
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      fontSize: '2.0rem',
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <div>
                                      <span>
                                        <strong>{match.live_home_score}</strong>
                                      </span>
                                      <span>-</span>
                                      <span>
                                        <strong>{match.live_away_score}</strong>
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="away-team-container">
                              <div className="team-container">
                                <img
                                  alt="away club badge"
                                  className="club-badge"
                                  height={70}
                                  src={`/badges/${match.away_team}.png`}
                                />
                                <span className="club-name">
                                  {match.away_team}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="minileague-prediction-table-row-inner-container">
                        {Date.parse(match.kick_off_time) < Date.now() ? (
                          <>
                            {match.members.map((member) => {
                              let pred = member.prediction;
                              if (pred) {
                                if (pred.home_pred === null) {
                                  pred = null;
                                }
                              }
                              let backgroundColor;
                              if (pred) {
                                if (pred.points > 0) {
                                  backgroundColor = 'bg-success';
                                } else if (pred.points < 0) {
                                  backgroundColor = 'bg-danger';
                                } else {
                                  backgroundColor = 'bg-dark';
                                }
                              }
                              return pred ? (
                                <div
                                  className="minileague-prediction-row row"
                                  key={member.username}
                                >
                                  <div
                                    className="col-6"
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <b>{member.username}</b>
                                  </div>
                                  <div className="col-3">
                                    {pred.error_message
                                      ? pred.error_message
                                      : `${pred.home_pred}-${pred.away_pred}`}
                                  </div>
                                  <div
                                    className="col-3 row"
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <div className="col-4">
                                      {pred.insurance && (
                                        <img
                                          className="chip-icon"
                                          alt="padlock icon"
                                          src="/icons/padlock.png"
                                          height={20}
                                          style={{
                                            paddingRight: 10,
                                            filter: 'none',
                                          }}
                                        />
                                      )}
                                      {pred.banker && (
                                        <img
                                          className="chip-icon"
                                          alt="dollar icon"
                                          src="/icons/dollar.png"
                                          height={20}
                                          style={{
                                            paddingRight: 10,
                                            filter: 'none',
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div className="col-8">
                                      <span
                                        className={`badge rounded-pill ${backgroundColor}`}
                                        style={{
                                          '--bs-bg-opacity': '.7',
                                          fontSize: '0.8rem',
                                          display: 'block',
                                        }}
                                      >
                                        {pred.points}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="minileague-prediction-row"
                                  key={member.username}
                                >
                                  <div className="col-md-6">
                                    <b>{member.username}</b>
                                  </div>
                                  <div className="col-md-6">No prediction</div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div style={{ textAlign: 'center' }}>
                            Not kicked off
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <hr style={{ color: 'white', opacity: 1 }} />
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </>
    ) : (
      <div className="no-mini-league-statement ask-friends-minileague">
        {`Ask your friends to join your mini-league - name: ${selectedMiniLeagueName}`}
      </div>
    )}
  </div>
);

MiniLeagueTable.propTypes = {
  setGameweek: PropTypes.func.isRequired,
  gameweek: PropTypes.number.isRequired,
  loaded: PropTypes.bool.isRequired,
  table: PropTypes.arrayOf(
    PropTypes.shape({
      home_team: PropTypes.string,
    }),
  ).isRequired,
  selectedMiniLeagueName: PropTypes.string,
};

MiniLeagueTable.defaultProps = {
  selectedMiniLeagueName: '',
};

export default MiniLeagueTable;
